












/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'EmptyOrderList'
})
export class EmptyOrderList extends Vue {
  public get startShoppingLink (): string {
    return '/'
  }
}

export default EmptyOrderList
