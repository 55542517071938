








import { Component } from 'vue-property-decorator'

import {
  ORDERS_VIEW_CONFIG_MAP,
  ORDERS_VIEW_KEY,
  OrdersViewConfig
} from '../../../contexts/orders/contracts/orders'
import { StructureConfigurable } from '../../../support/mixins'

import { OrdersList } from '../../orders/organisms/OrdersList'

import { ProfileWrapper } from '../../profile/shared'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Orders>({
  name: 'Orders',
  components: { OrdersList, ProfileWrapper },
  created () {
    this.config = this.getComponentConfig(ORDERS_VIEW_KEY, { ...ORDERS_VIEW_CONFIG_MAP })
  }
})
export class Orders extends StructureConfigurable {
  protected config!: OrdersViewConfig

  public get hasMargin (): boolean {
    return this.getConfigProperty<boolean>('hasMargin')
  }
}

export default Orders
